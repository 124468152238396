import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/seo'

import Hero from '../components/Hero'
import DebatesPagination from '../components/DebatesPagination'

const debatesWeekPage = () => (
  <Layout>
    <Seo title="Debates of the Week | Talk of the Town" />

    <Hero text="Debates of the Week" />

    <DebatesPagination restUrl="https://jsonplaceholder.typicode.com/posts" />
  </Layout>
)

export default debatesWeekPage
